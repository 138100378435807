@font-face {
  font-family: IranSans;
  font-style: normal;
  font-weight: 200;
  src: url("./assets/fonts/IranSansX/woff/IRANSansXFaNum-Regular.woff"),
    url("./assets/fonts/IranSansX/woff2/IRANSansXFaNum-Regular.woff2")
      format("woff2");
}
* {
  font-family: Roboto, IranSans;
}
.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.loading .ant-spin {
  color: #0f4eeb;
}
.grecaptcha-badge {
  display: none;
}
.home-boxs-items {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 12px;
  border: solid 1px #707070;
  padding: 0.2rem 0.5rem;
  margin: 0.3rem 0;
  color: #455560;
}
.home-extend-items {
  border-radius: 12px;
  border: solid 1px #707070;
  margin: 0.5rem 0;
  color: #455560;
}
.home-boxs-items span {
  color: #455560;
  border: 1px solid #707070;
  border-radius: 50%;
  padding: 0.2rem;
}
.menu-subtitle {
  margin-top: 50px;
  padding: 0 25px;
  line-height: normal;
}
.rtl {
  direction: rtl;
  text-align: right;
}
.ltr {
  direction: ltr;
  text-align: left;
}

#iframe {
  height: 100vh;
}

.dashboardSlider img {
  height: 400px;
  width: 100%;
  border-radius: 15px;
}

.vncScreen {
  position: fixed;
  top: 0;
  left: 0;
  background: black;
  z-index: 99999;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.vncScreen p,
.vncScreen h3 {
  color: white;
}

.vncHeadTitle {
  background: #2e2e2e;
  padding: 15px;
}

@media only screen and (max-width: 600px) {
  .chat-modal-container {
    max-width: 18rem !important;
  }
  .tradingview {
    display: none!important;
  }
  .dashboardSlider img {
    height: auto!important;
  }
  .dashboardSidebar {
    display: flex!important;
    flex-wrap: wrap;
  }
  .ads {
    order: 4;
  }
}
